const config = {
  ENVIRONMENT_NAME: 'prod',
  forwoodId: {
    URL: 'https://id.evolution.forwoodsafety.com',
    APP_CLIENT_ID: '72rvru54m6uvise9ln2ij1jqvj',
    USER_TOKEN_URL: 'https://cvijd1u1yl.execute-api.us-west-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://8dp8p4yjqc.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'admin-container-1.9.1',
    HOSTNAME: 'https://admin.evolution.forwoodsafety.com',
    COMPANY_MICROFRONTEND_URL: 'https://company.evolution.forwoodsafety.com',
    PHYSICAL_LOCATION_MICROFRONTEND_URL: 'https://locations.evolution.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.evolution.forwoodsafety.com',
    WEBSOCKET: 'wss://n85utmo5h5.execute-api.us-west-2.amazonaws.com/prod'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;